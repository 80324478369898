'use strict';

export default class PassengerFile extends React.Component {

    constructor(props) {
        super(props);
        this.id = `PassengerFile_${_.uniqueId()}`;
    }

    componentDidMount() {
        const {onChange} = this.props;
        const $this = $(`#${this.id}`);
        $this.find(".input-file").on("change", function(){
            const files = !!this.files ? this.files : [];
            if (!files.length || !window.FileReader) return;
            if (/^image/.test(files[0].type)){
                const reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onloadend = function() {
                    onChange({
                        src: this.result,
                        data: files[0],
                    });
                }
            }
        });
    }

    render() {
        const {label, file} = this.props;
        const src = (!!file && file.src) || file;
        const selected = !!src;
        return <div id={this.id} className={clsx('passenger-form__file', {selected})}>
            <input type="file" className="input-file"/>
            <div className="file-thumb" style={{backgroundImage: `url(${src})`}}/>
            <div className="cover">
                <i className="icon-passport"></i>
                <span>{!selected ? label : "Выбрать файл"}</span>
            </div>
        </div>
    }
}
