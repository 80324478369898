'use strict';

import Loading from '../components/Loading';
import PassengerFile from '../components/PassengerFile';

const FormInput =  React.lazy(() => import( /* webpackChunkName: "FormInput" */ '../components/FormInput'));

class EditPassengerModal extends React.Component {
    constructor(props) {
        super(props);
        this.initState = {
            fid: null,
            ufid: null,
            ufpid: null,
            firstName: '',
            lastName: '',
            thirdName: '',
            birthDate: '',
            passports: {},
            errors: {},
            error_message: null,
            isFetching: false,
        }
        this.state = this.initState;

        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        listenEvent('react.flightDataPassenger', data => {
            if(data.passenger === null) {
                this.setState(this.initState);
            } else {
                this.setState({
                    ...this.initState,
                    fid: data.fid,
                    ufid: data.ufid,
                    ...data.passenger,
                    passports: data.passenger.passports || {},
                });
            }
        })
    }

    handleChangePassport(key, file) {
        this.setState({passports: {
            ...this.state.passports,
            [key]: file,
        }})
    }

    handleClose(e) {
        if(e) e.preventDefault();
        this.setState(this.initState)
        $.fancybox.close();
    }

    handleSubmit(e) {
        e.preventDefault();
        const {fid, ufid, ufpid, firstName, lastName, thirdName, passports, birthDate} = this.state;
        this.setState({isFetching: true})
        flightDataAction.savePassenger({fid, ufid, ufpid, firstName, lastName, thirdName, birthDate, passports}).then(response => {
            this.setState({isFetching: false, errors: {}, error_message: null})
            if(response.status === 'success') {
                raiseEvent(ufpid !== null ? 'react.updatePassenger' : 'react.addPassenger', {
                    passenger: response.passenger
                });
                this.handleClose()
            } else {
                this.setState({errors: response.errors, error_message: response.message || null})
            }
        });
    }

    render() {
        const {ufpid, firstName, lastName, thirdName, birthDate, passports, errors, error_message, isFetching} = this.state;

        return <div>
            <div className="passenger-modal__title">
                {ufpid === null ? tMsg('Добавление пассажира') : tMsg('Редактирование пассажира')}
            </div>
            <form action="">
                <div className="passenger-form">
                    <div className="passenger-row__info">
                        <div className="passenger-form__row">
                            <React.Suspense>
                                <FormInput
                                    className={'passenger-form__col'}
                                    label={tMsg('Фамилия')}
                                    value={lastName}
                                    onChange={lastName => this.setState({lastName})}
                                    error={errors.surname}
                                />
                            </React.Suspense>
                            <React.Suspense>
                                <FormInput
                                    className={'passenger-form__col'}
                                    label={tMsg('Имя')}
                                    value={firstName}
                                    onChange={firstName => this.setState({firstName})}
                                    error={errors.firstname || error_message}
                                />
                            </React.Suspense>
                            <React.Suspense>
                                <FormInput
                                    className={'passenger-form__col'}
                                    label={tMsg('Отчество')}
                                    value={thirdName}
                                    onChange={thirdName => this.setState({thirdName})}
                                    error={errors.patronymic}
                                />
                            </React.Suspense>
                        </div>
                        <div className="passenger-form__row2">
                            <React.Suspense>
                                <FormInput
                                    type={'date'}
                                    readOnly={true}
                                    className={'passenger-form__col'}
                                    label={tMsg('Дата рождения')}
                                    value={birthDate}
                                    onChange={birthDate => this.setState({birthDate})}
                                    error={errors.bday}
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    {passports && <div className="passenger-form__img">
                        <PassengerFile
                            label={`${tMsg('Паспорт')} 1`}
                            file={passports['rus']}
                            onChange={file => this.handleChangePassport('rus', file)}
                        />
                        <PassengerFile
                            label={`${tMsg('Паспорт')} 2`}
                            file={passports['rus_back']}
                            onChange={file => this.handleChangePassport('rus_back', file)}
                        />
                        <PassengerFile
                            label={`${tMsg('Паспорт')} 3`}
                            file={passports['int']}
                            onChange={file => this.handleChangePassport('int', file)}
                        />
                        <PassengerFile
                            label={`${tMsg('Паспорт')} 4`}
                            file={passports['int_back']}
                            onChange={file => this.handleChangePassport('int_back', file)}
                        />
                    </div>}
                    <div className="passenger-form__btns">
                        <a onClick={this.handleClose} className="button button-white">{tMsg('Отмена')}</a>
                        <button onClick={this.handleSubmit} className="button button-base">
                            {ufpid === null ? tMsg('Добавить пассажира') : tMsg('Сохранить пассажира')}
                        </button>
                    </div>
                </div>
            </form>
            <Loading active={isFetching}/>
        </div>
    }
}

const domContainer = document.querySelector('#reactEditPassengerModal');
const root = ReactDOM.createRoot(domContainer);
root.render(React.createElement(EditPassengerModal));
